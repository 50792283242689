import React from "react";
import SimpleMDE from "simplemde";

import { update } from "../../google";
import { LinkSuggest } from "./LinkSuggest";

export class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: this.props.card.name || "", tags: [] };
  }
  componentDidMount() {
    this.simplemde = new SimpleMDE({
      element: this.refs.area,
      initialValue: this.props.card.name + "\n" + this.props.card.body,
      spellChecker: false,
      toolbar: false,
      status: false
    });
    this.simplemde.codemirror.on("change", e => {
      this.setState({ shouldSave: true });
    });
    this.shouldEndSaveLoop = false;
    this.saveLoop();
  }

  getSimpleMDE() {
    return this.simplemde;
  }

  async save(saveTag = false) {
    const card = this.props.card;
    const ary = this.simplemde.value().split("\n");
    const name = ary.shift();
    const body = ary.join("\n");
    if (name) {
      await update(card.id, name, body, true, saveTag);
    }
  }

  async saveLoop() {
    if (this.shouldEndSaveLoop) {
      return true;
    }
    if (this.state.shouldSave) {
      await this.save();
      this.setState({ shouldSave: false });
      setTimeout(() => {
        this.saveLoop();
      }, 1000);
    } else {
      setTimeout(() => {
        this.saveLoop();
      }, 20);
    }
  }

  async switchEdit() {
    this.shouldEndSaveLoop = true;
    this.save(true).then(() => {
      this.props.forceReload();
    });
    this.props.switchEdit();
  }

  switchLink() {
    const now = this.refs.link.style.display;
    if (now === "none") {
      this.refs.link.style.display = "block";
    } else {
      this.refs.link.style.display = "none";
    }
  }

  render() {
    return (
      <div>
        <button
          className="Button fa fa-link"
          onClick={() => this.switchLink()}
        ></button>
        <button className="Button" onClick={() => this.switchEdit()}>
          Done
        </button>
        <span ref="saving" style={{ display: "none" }}>
          Saving...
        </span>
        <div ref="link" style={{ display: "none" }}>
          <LinkSuggest
            simplemde={() => this.getSimpleMDE()}
            done={() => this.switchLink()}
          />
        </div>
        <textarea ref="area" style={{ display: "none" }} />
      </div>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.name !== nextState.name) {
      return true;
    } else {
      return false;
    }
  }
}
