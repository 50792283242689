import React, { ReactElement, useState, useEffect } from "react";
import firebase from "firebase/app";
import { Welcome } from "../Welcome";

export const Authenticate: React.FC<{ children: ReactElement }> = ({
  children
}) => {
  const [login, setLogin] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (!!user) {
        setLogin(true);
      }
    });
  }, []);

  if (login) return children;
  return <Welcome />;
};
