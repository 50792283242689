import React from "react";
import queryString from "query-string";
import uuidv4 from "uuid/v4";
import { Link } from "react-router-dom";

import { Card } from "../Card";
import { Search } from "../Search";
import { getCard, getHome, deleteCard } from "../../google";

export class ShowCard extends React.Component {
  constructor(props) {
    super(props);
    const params = queryString.parse(window.location.search);
    this.state = { card: null, query: params.query || "" };
  }

  async componentDidMount() {
    const card = await getCard(this.props.match.params.card_id);
    this.setState({ card: card });
    getHome();
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.card_id !== this.props.match.params.card_id) {
      const card = await getCard(nextProps.match.params.card_id);
      this.setState({ card: card });
    }
  }

  shouldComponentUpdate() {
    return true;
  }

  async delete() {
    if (window.confirm("Really?")) {
      await deleteCard(this.state.card.id);
      window.history.back();
    }
  }

  render() {
    const newUrl = `/cards/${uuidv4()}?edit=true`;
    const date = new Date();
    const logUrl = `/log/${date.getFullYear()}-${date.getMonth() +
      1}-${date.getDate()}`;
    return (
      <div>
        <div className="Menu" id="topbar">
          <Search
            setListState={opts => this.setState(opts)}
            history={this.props.history}
            match={this.props.match}
          />
          <Link to="/" className="Button ButtonMenu">
            Home
          </Link>
          <Link to={newUrl} className="Button ButtonMenu">
            Create
          </Link>
          <Link to={logUrl} className="Button ButtonMenu">
            Log
          </Link>
        </div>
        {this.state.card !== null ? (
          <Card
            card={this.state.card}
            match={this.props.match}
            history={this.props.history}
          />
        ) : null}
        <div>
          <br />
          <br />
          <br />
          <button
            className="Button"
            id="delete"
            style={{ fontSize: "x-small" }}
            onClick={() => this.delete()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}
