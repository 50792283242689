import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Authenticate } from "./components/Authenticate";
import { List } from "./components/List";
import { Log } from "./components/Log";
import { Settings } from "./components/Settings";
import { ShowCard } from "./components/ShowCard";

const StyleExtention: React.FC = () => {
  if (!!navigator.userAgent.match(/Windows/)) {
    return <style>{".CodeMirror-scroll{ overflow:hidden!important }"}</style>;
  } else {
    return null;
  }
};

const App: React.FC = () => {
  return (
    <Authenticate>
      <div className="ReactWorld">
        <BrowserRouter>
          <div>
            <StyleExtention />
            <Route exact path="/" component={List} />
            <Route exact path="/cards/:card_id" component={ShowCard} />
            <Route exact path="/log/:date" component={Log} />
            <Route exact path="/settings" component={Settings} />
          </div>
        </BrowserRouter>
      </div>
    </Authenticate>
  );
};

export default App;
