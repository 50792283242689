import React from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import breaks from "remark-breaks";

import { getCard, getCardFromName } from "../../google";

const RouterLink = props => {
  return props.href.match(/^(https?:)?\/\//) ? (
    <a href={props.href}>{props.children}</a>
  ) : (
    <Link to={props.href}>{props.children}</Link>
  );
};

export class MdBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = { body: this.tab(this.props.card.body) };
  }
  componentDidMount() {
    this.hashTag();
    this.showRef();
  }
  tab(str) {
    return str.replace(/\t/g, "  ");
  }
  hashTag() {
    const hashTagRegExp = /#([Ａ-Ｚａ-ｚA-Za-z一-鿆0-9０-９ぁ-ヶｦ-ﾟー_\-・]+)/g;
    this.setState({
      body: this.state.body.replace(hashTagRegExp, "[#$1](/cards/$1)")
    });
  }
  async showRef() {
    const match = this.state.body.match(/ref\(.+?\)/g);
    if (match) {
      match.forEach(async m => {
        const id = m.split("(")[1].split(")")[0];
        const card = await getCard(id);
        if (!card.isNew) {
          this.setState({
            body: this.state.body.replace(
              m,
              `[${card.name}](/cards/${card.id})`
            )
          });
        } else {
          const card = await getCardFromName(id);
          if (card) {
            this.setState({
              body: this.state.body.replace(
                m,
                `[${card.name}](/cards/${card.id})`
              )
            });
          }
        }
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ body: this.tab(nextProps.card.body) }, () => {
      this.hashTag();
      this.showRef();
    });
  }

  render() {
    const url = "/cards/" + this.props.card.id;
    return (
      <div>
        <h1 className="card_title">
          <Link to={url}>{this.props.card.name}</Link>
        </h1>
        <Markdown
          source={this.state.body}
          renderers={{ link: RouterLink }}
          plugins={[breaks]}
          className="RealCard"
        />
        <div style={{ display: "flex" }}>
          <div style={{ display: "table" }}>
            <button
              className="edit Button"
              onClick={() => this.props.switchEdit()}
            >
              Edit
            </button>
          </div>
          <div style={{ fontSize: "x-small" }}>
            Created At {new Date(this.props.card.createdTime).toLocaleString()}
            <br />
            Updated At {new Date(this.props.card.modifiedTime).toLocaleString()}
          </div>
        </div>
      </div>
    );
  }
}
