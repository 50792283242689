import firebase from "@firebase/app";
import "@firebase/firestore";
import "@firebase/auth";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

const config = {
  apiKey: "AIzaSyBZ8kkZzp9xNFLvzzDswL7ykY3DHjmlKko",
  authDomain: "firememo-6f108.firebaseapp.com",
  databaseURL: "https://firememo-6f108.firebaseio.com",
  projectId: "firememo-6f108",
  storageBucket: "firememo-6f108.appspot.com",
  messagingSenderId: "702688554039"
};
firebase.initializeApp(config);

ReactDOM.render(<App />, document.getElementById("root"));
