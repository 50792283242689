import algoliasearch from "algoliasearch";
import firebase from "firebase/app";
const searchClient = algoliasearch(
  "EP58OWIDM9",
  "cf71310cccbcc228589db08db934cfb3"
);
const searchIndex = searchClient.initIndex("cards");

const cardCollection = () => {
  const user = firebase.auth().currentUser;
  return firebase
    .firestore()
    .collection("user")
    .doc(user.uid)
    .collection("cards");
};

/**
  Card
    name: string
    body: string
    createdTime: int
    modifiedTime: int
**/

// キャッシュ初期化
if (window.cardCache === undefined) {
  window.cardCache = {};
  window.homeCache = null;
}

// カードのドキュメントを UI 側が想定する形式にしつつキャッシュ
const docToCard = doc => {
  let card = doc.data();
  const at = new Date().getTime();

  // find_or_initialize 的なことしてる
  if (!card) {
    card = {
      name: "",
      body: "",
      createdTime: at,
      modifiedTime: at,
      isNew: true
    };
  }

  card.id = doc.id;

  // 高速取得できるように cache にぶっこみ
  window.cardCache[card.id] = card;
  return card;
};

// Algolia の検索結果を UI 側が想定する形式にコンバートしつつキャッシュ
const searchToCard = hit => {
  hit.id = hit.objectID;
  window.cardCache[hit.id] = hit;
  return hit;
};

// ホーム画面取得
// async で呼ばれること前提に Promise 返してる
//
// キャッシュないときにこの関数呼ばれたらキャッシュしてから返す
const getHome = func => {
  // キャッシュがあれば Promise に包んでキャッシュを返す
  if (window.homeCache) {
    return new Promise(resolve => resolve(window.homeCache));
  } else {
    return new Promise(async (resolve, reject) => {
      // hashTag 経由で作られたエントリは手編集するまで一覧しない
      cardCollection()
        .where("active", "==", true)
        .orderBy("createdTime", "desc")
        .limit(50)
        .get()
        .then(async queryResult => {
          const result = [];
          const index = await getCard("index");
          if (index) {
            result.push(index);
          }
          queryResult.forEach(doc => {
            if (doc.id !== "index") {
              result.push(docToCard(doc));
            }
          });
          // キャッシュして次回取得を速く
          window.homeCache = result;
          resolve(result);
        });
    });
  }
};

// Algolia を使って検索する
// keyword が空だと getHome()
// async 付きで呼ばれる前提で Promise 返す
const search = keyword => {
  if (keyword === "") {
    return getHome();
  }
  return new Promise(async (resolve, reject) => {
    const user = firebase.auth().currentUser;
    const res = await searchIndex.search({
      query: `${keyword}`,
      filters: `user_id:${user.uid}`,
      hitsPerPage: 200
    });
    const cards = res.hits
      .map(e => searchToCard(e))
      .sort((a, b) => b.modifiedTime - a.modifiedTime);
    resolve(cards);
  });
};

// Name に絞って検索
// とりあえず正規表現で絞ってるけど本来 Algolia でなんとかすべき
const searchName = async keyword => {
  const result = await search(keyword);
  return result.filter(e => e.name.match(new RegExp(keyword)));
};

// カード一枚を取得
const getCard = async card_id => {
  if (window.cardCache[card_id]) {
    return window.cardCache[card_id];
  } else {
    const doc = await cardCollection()
      .doc(card_id)
      .get();
    return docToCard(doc);
  }
};

// 名前からカードを取得
const getCardFromName = async name => {
  const c = Object.keys(window.cardCache)
    .filter(k => window.cardCache[k] && window.cardCache[k].name === name)
    .map(k => window.cardCache[k])[0];
  if (c) {
    return new Promise(r => r(c));
  } else {
    const docs = await cardCollection()
      .where("name", "==", name)
      .limit(1)
      .get();
    if (docs.docs[0] && docs.docs[0].data) {
      return docToCard(docs.docs[0]);
    } else {
      return null;
    }
  }
};

const flushCache = id => {
  if (window.cardCache[id]) {
    window.cardCache[id] = null;
  }
  window.homeCache = null;
};

// カードを更新または作成
// active が false というのは一覧に表示しないの意、ハッシュタグ経由の自動作成で発動
// saveTag が false というのは、記事作成中の自動保存の意。この場合ハッシュタグを処理しない。これによりゴミ記事ができない。
const update = async (id, name, body, active = true, saveTag = false) => {
  const at = new Date().getTime();
  const ref = cardCollection().doc(id);
  const doc = await ref.get();
  if (!doc.data()) {
    await ref.set({ createdTime: at });
  }
  await ref.update({
    name: name,
    body: body.replace(/\t/g, "  "),
    modifiedTime: at,
    active: active
  });
  flushCache(id);
  if (saveTag) {
    proccessHashTag(body);
  }
  return new Promise((resolve, reject) => resolve());
};

// ハッシュタグを処理して記事を作成
const proccessHashTag = async body => {
  const hashTagRegExp = /#([Ａ-Ｚａ-ｚA-Za-z一-鿆0-9０-９ぁ-ヶｦ-ﾟー_\-・]+)/g;
  const match = body.match(hashTagRegExp) || [];
  const tags = match
    .map(e => e.replace(/^#/, ""))
    .filter((x, i, self) => self.indexOf(x) === i);
  tags.forEach(async tag => {
    const card = await getCard(tag);
    if (card.isNew) {
      // ハッシュタグと同名の記事がある場合、それをコピーしてしまう
      const cardByName = await getCardFromName(tag);
      if (!cardByName) {
        update(tag, tag, "from HashTag", false);
      } else {
        window.cardCache = {};
        window.homeCache = null;
        await update(tag, tag, cardByName.body);
        await cardCollection()
          .doc(cardByName.id)
          .delete();
      }
    }
  });

  const match_links = body.match(/\[.*?\]/g) || [];
  const links = match_links
    .map(e => e.replace(/^.|.$/g, ""))
    .filter((x, i, self) => self.indexOf(x) === i);
  links.forEach(async link => {
    const card = await getCard(link);
    if (card.isNew) {
      // ハッシュタグと同名の記事がある場合、それをコピーしてしまう
      const cardByName = await getCardFromName(link);
      if (!cardByName) {
        update(link, link, "from HashTag", false);
      } else {
        window.cardCache = {};
        window.homeCache = null;
        await update(link, link, cardByName.body);
        await cardCollection()
          .doc(cardByName.id)
          .delete();
      }
    }
  });
};

// 関連エントリの取得
const related = card_id => {
  return new Promise(async (resolve, reject) => {
    const card = await getCard(card_id);

    const result = [];

    let refed = null;
    let nameRefed = null;
    if (card_id.length === 36) {
      refed = (await search(card_id)).filter(e => e.id !== card_id);
      nameRefed = (await search(card.name)).filter(e => e.id !== card_id);
    } else {
      const r = new RegExp(`#${card.name}`);
      const r2 = new RegExp(`#${card.id}`);
      refed = (await search(`#${card.id}`)).filter(
        e => e.id !== card_id && e.body.match(r2)
      );
      nameRefed = (await search(`#${card.name}`)).filter(
        e => e.id !== card_id && e.body.match(r)
      );
    }
    const scrapLinked = (await search(`[${card.name}]`)).filter(e => {
      const r = new RegExp(`\\[${card.name}\\]`);
      return e.id !== card_id && e.body.match(r);
    });
    const rels = refed.concat(nameRefed).concat(scrapLinked);
    const uniqueRels = Object.keys(
      // eslint-disable-next-line
      rels.map(c => c.id).reduce((r, x) => ((r[x] = 1), r), {})
    ).map(key => {
      return rels.filter(r => r.id === key)[0];
    });
    if (uniqueRels.length > 0) {
      result.push({
        name: card.name || card.id,
        cards: uniqueRels
      });
    }

    const hashTagRegExp = /#([Ａ-Ｚａ-ｚA-Za-z一-鿆0-9０-９ぁ-ヶｦ-ﾟー_\-・]+)/g;

    const tags = card.body.match(hashTagRegExp) || [];
    const links = (card.body.match(/\[.+?\]/g) || []).map(e =>
      e.replace(/^.|.$/g, "")
    );

    const promises = [];
    const targets = tags
      .concat(links)
      .filter((x, i, self) => self.indexOf(x) === i);
    targets.forEach(tag => {
      const user = firebase.auth().currentUser;
      promises.push(
        searchClient.initIndex("cards").search({
          query: `${tag}`,
          filters: `user_id:${user.uid}`,
          hitsPerPage: 200
        })
      );
    });

    Promise.all(promises).then(results => {
      targets.forEach((tag, i) => {
        const reg = new RegExp(`#${tag.replace(/\)$/, "")}`);
        const cards = results[i].hits
          .map(e => {
            return searchToCard(e);
          })
          .filter(e => {
            return (
              e.id !== card.id &&
              (!!e.body.match(reg) ||
                !!e.body.match(new RegExp(`\\[${tag.replace(/\)$/, "")}\\]`)))
            );
          })
          .slice(0, 20);
        const collection = results[i].query;
        result.push({
          name: collection,
          cards: cards
        });
      });
      resolve(result);
    });
  });
};

// キャッシュ温めマン
const warmUpCache = async () => {
  const cards = await cardCollection()
    .orderBy("modifiedTime", "desc")
    .limit(500)
    .get();
  cards.forEach(doc => docToCard(doc));
};

// ログ
// ある一日に更新されたカード一覧
const log = async date => {
  const startAt = date.getTime();
  const endAt = startAt + 86400 * 1000;
  const cards = await cardCollection()
    .where("active", "==", true)
    .where("modifiedTime", ">", startAt)
    .where("modifiedTime", "<", endAt)
    .limit(500)
    .get();
  const result = [];
  cards.forEach(d => result.push(docToCard(d)));
  return result;
};

// 削除
const deleteCard = async id => {
  await cardCollection()
    .doc(id)
    .delete();
};

export {
  getHome,
  search,
  getCard,
  update,
  related,
  warmUpCache,
  getCardFromName,
  searchName,
  deleteCard,
  log
};
