import React from "react";
import { update } from "../../google";

export class MobileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: this.props.card.name || "", tags: [] };
  }

  async save(saveTag = false) {
    const card = this.props.card;
    const ary = this.refs.area.value.split("\n");
    const name = ary.shift();
    const body = ary.join("\n");
    if (name) {
      await update(card.id, name, body, true, saveTag);
    }
  }

  async switchEdit() {
    this.save(true).then(() => {
      this.props.forceReload();
    });
    this.props.switchEdit();
  }

  render() {
    return (
      <div>
        <button className="Button" onClick={() => this.switchEdit()}>
          Done
        </button>
        <br />
        <textarea
          ref="area"
          defaultValue={this.props.card.name + "\n" + this.props.card.body}
          style={{ width: "100%", height: "600px" }}
        />
      </div>
    );
  }
}
