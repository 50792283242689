import React from "react";
import Autosuggest from "react-autosuggest";

import { searchName } from "../../google";

export class LinkSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = { query: "", suggestions: [] };
  }
  submit(e) {
    e.preventDefault();

    const line = this.props.simplemde().codemirror.getCursor().line;
    const ch = this.props.simplemde().codemirror.getCursor().ch;

    this.props
      .simplemde()
      .codemirror.replaceRange(
        `[${this.state.query}]`,
        { line: line, ch: ch },
        { line: line, ch: ch }
      );
    this.props.simplemde().codemirror.focus();

    this.props.done();
  }
  change(e, { newValue, method }) {
    this.setState({ query: newValue });
  }

  async onSuggestionsFetchRequested(query) {
    if (query) {
      const result = (await searchName(query.value)).sort(
        (a, b) => b.modifiedTime - a.modifiedTime
      );
      this.setState({ suggestions: result });
    } else {
    }
  }

  getSuggestionValue(e) {
    return e.name;
  }

  renderSuggestion(e) {
    return <div>{e.name}</div>;
  }

  render() {
    return (
      <form onSubmit={e => this.submit(e)} className="LinkSuggest">
        <Autosuggest
          suggestions={this.state.suggestions}
          getSuggestionValue={e => this.getSuggestionValue(e)}
          renderSuggestion={e => this.renderSuggestion(e)}
          onSuggestionsFetchRequested={e => this.onSuggestionsFetchRequested(e)}
          onSuggestionsClearRequested={e => this.onSuggestionsFetchRequested(e)}
          inputProps={{
            value: this.state.query,
            onChange: (e, opts) => {
              this.change(e, opts);
            },
            placeholder: "Search..."
          }}
        />
      </form>
    );
  }
}
