import React from "react";
import { Redirect } from "react-router-dom";
import Autosuggest from "react-autosuggest";

import { search, searchName } from "../../google";

export class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { query: this.props.query || "", suggestions: [] };
  }

  // show_card からきてるときは普通に遷移
  // list からだったら検索
  search(e) {
    if (this.props.match.path !== "/cards/:card_id") {
      e.preventDefault();
      const query = this.state.query || "";
      search(query).then(result => {
        this.props.setListState({ cards: result });
        if (query === "") {
          this.query = "";
          this.props.history.push("/");
        } else {
          this.query = query;
          window.history.pushState(
            "",
            "",
            "/?query=" + encodeURIComponent(query)
          );
        }
      });
    } else {
      e.preventDefault();
      this.setState({ redirect: true });
    }
  }
  change(e, { newValue, method }) {
    this.setState({ query: newValue });
  }
  getSuggestionValue(e) {
    return e.name;
  }
  async onSuggestionsFetchRequested(query) {
    if (query) {
      const result = (await searchName(query.value)).sort(
        (a, b) => b.modifiedTime - a.modifiedTime
      );
      this.setState({ suggestions: result });
    } else {
    }
  }
  renderSuggestion(e) {
    return <div>{e.name}</div>;
  }

  render() {
    if (this.state.redirect) {
      const url =
        this.state.query === ""
          ? "/"
          : `/?query=${encodeURIComponent(this.state.query)}`;
      return <Redirect to={url} />;
    } else {
      return (
        <form onSubmit={e => this.search(e)} style={{ display: "inline" }}>
          <Autosuggest
            suggestions={this.state.suggestions}
            getSuggestionValue={e => this.getSuggestionValue(e)}
            renderSuggestion={e => this.renderSuggestion(e)}
            onSuggestionsFetchRequested={e =>
              this.onSuggestionsFetchRequested(e)
            }
            onSuggestionsClearRequested={e =>
              this.onSuggestionsFetchRequested(e)
            }
            inputProps={{
              value: this.state.query,
              onChange: (e, opts) => {
                this.change(e, opts);
              },
              placeholder: "Search..."
            }}
          />
        </form>
      );
    }
  }
}
