import React from "react";
import queryString from "query-string";
import hljs from "highlightjs";
import "highlightjs/styles/solarized-light.css";

import { getCard } from "../../google";
import { MdBody } from "./MdBody";
import { Edit } from "./Edit";
import { MobileEdit } from "./MobileEdit";
import { Related } from "./Related";

export class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = { edit: false, card: this.props.card, related: true };
  }

  // Edit のほうに渡して、 Edit 側で Save 完了したら呼び出す
  async forceReload() {
    const card = await getCard(this.props.card.id);
    this.setState({ card: card, related: false });
    setTimeout(() => {
      this.setState({ related: true });
    }, 200);
  }

  // 編集画面とプレビュー画面の切り替え
  switchEdit() {
    const current = this.state.edit;
    const toolbar = !current ? "none" : "block";
    ["#topbar", "#related", "#delete"].forEach(s => {
      const elm = document.querySelector(s);
      if (elm) {
        elm.style.display = toolbar;
      }
    });
    window.history.pushState("", "", `/cards/${this.state.card.id}`);
    this.setState({ edit: !current });
    this.syntaxHilight();
  }

  // edit=true なら Edit 直行
  goEdit() {
    const params = queryString.parse(window.location.search);
    if (params.edit === "true" && !this.state.edit) {
      this.switchEdit();
    }
  }

  syntaxHilight() {
    document.querySelectorAll("pre code").forEach(elm => {
      hljs.highlightBlock(elm);
    });

    document.querySelectorAll(".RealCard a").forEach(elm => {
      const href = elm.attributes.href.value;
      if (href === "") {
        elm.addEventListener("click", e => {
          e.preventDefault();
          const url = `/cards/${e.target.textContent}`;
          this.props.history.push(url);
        });
      }
    });
  }

  componentDidMount() {
    this.goEdit();
    this.syntaxHilight();
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ card: nextProps.card });
    setTimeout(() => {
      this.syntaxHilight();
    }, 20);
    // Edit から Back したら Edit 解除
    const current = this.state.edit;
    if (current && !nextProps.card.isNew) {
      this.switchEdit();
    } else {
      // カード表示中に Create 押しても Edit 直行
      if (nextProps.card.id !== this.props.card.id) {
        this.goEdit();
      }
    }
  }

  related() {
    if (this.props.match.path === "/cards/:card_id" && this.state.related) {
      return <Related card={this.props.card} />;
    } else {
      return null;
    }
  }

  renderEdit() {
    const isIOS = /iP(hone|(o|a)d)/.test(navigator.userAgent);
    if (isIOS) {
      return (
        <MobileEdit
          card={this.state.card}
          switchEdit={() => this.switchEdit()}
          forceReload={() => this.forceReload()}
        />
      );
    } else {
      return (
        <Edit
          card={this.state.card}
          switchEdit={() => this.switchEdit()}
          forceReload={() => this.forceReload()}
        />
      );
    }
  }

  render() {
    const listStyle = { listStyle: "none" };

    return (
      <li style={listStyle}>
        <div>
          {this.state.edit ? (
            this.renderEdit()
          ) : (
            <MdBody
              card={this.state.card}
              switchEdit={() => this.switchEdit()}
            />
          )}
        </div>
        {this.related()}
      </li>
    );
  }
}
