import React from "react";
import { related } from "../../google";
import { Link } from "react-router-dom";
import uuidv4 from "uuid/v4";

class One extends React.Component {
  li(card) {
    return (
      <Link
        className="Button"
        style={{
          backgroundColor: "#EEE8AA",
          margin: "2px",
          fontSize: "x-small"
        }}
        key={uuidv4()}
        to={`/cards/${card.id}`}
      >
        {card.name}
      </Link>
    );
  }
  render() {
    if (this.props.data.cards.length > 0) {
      return (
        <div>
          <h1 style={{ fontSize: "1em" }}>{this.props.data.name}</h1>
          <div>{this.props.data.cards.map(c => this.li(c))}</div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export class Related extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }
  async componentDidMount() {
    const data = await related(this.props.card.id);
    this.setState({ data: data });
  }
  async componentWillReceiveProps(nextProps) {
    if (
      nextProps.card.id !== this.props.card.id ||
      nextProps.card.body !== this.props.card.body
    ) {
      const data = await related(nextProps.card.id);
      this.setState({ data: data });
    }
  }

  one(data) {
    return <One data={data} key={data.name} />;
  }

  render() {
    return (
      <div id="related">
        <hr />
        {this.state.data.map(d => this.one(d))}
      </div>
    );
  }
}
