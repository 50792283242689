import queryString from "query-string";
import React, { useEffect, useState, ReactElement } from "react";
import { Link, Redirect } from "react-router-dom";
import uuidv4 from "uuid/v4";
import { search } from "../../google";
import { Card } from "../Card";
import { Search } from "../Search";

export const List: React.FC<{ match: any; history: any }> = props => {
  const params = queryString.parse(window.location.search);
  const [query, setQuery] = useState(params.query || "");
  const [cards, setCards] = useState([]);

  useEffect(() => {
    (async () => {
      setCards(await search(query));
    })();
  }, [query]);

  const renderCards = (): ReactElement => {
    if (cards.map(e => e.name).indexOf(query) > -1) {
      const card = cards.filter(e => e.name === this.state.query)[0];
      const url = `/cards/${card.id}`;
      return <Redirect to={url} />;
    } else {
      return (
        <ul className="cards">
          {cards.map(card => {
            return (
              <Card
                card={card}
                key={card.id}
                match={props.match}
                history={props.history}
              />
            );
          })}
        </ul>
      );
    }
  };

  const newUrl = `/cards/${uuidv4()}?edit=true`;
  const date = new Date();
  const logUrl = `/log/${date.getFullYear()}-${date.getMonth() +
    1}-${date.getDate()}`;
  return (
    <div>
      <div className="Menu">
        <Search
          setListState={(opts: { query: any }) => setQuery(opts.query)}
          query={query}
          history={props.history}
          match={props.match}
        />
        <Link to={newUrl} className="Button ButtonMenu">
          Create
        </Link>
        <Link to={logUrl} className="Button ButtonMenu">
          Log
        </Link>
      </div>
      {renderCards()}
    </div>
  );
};
