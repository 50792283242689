import * as React from "react";
import firebase from "firebase/app";

export const Welcome: React.FC = () => {
  const start = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {})
      .catch(e => {
        console.log(e);
      });
  };
  return (
    <>
      <h1>FirePad</h1>
      <p>
        Memo app that can be written with Markdown. All code runs on your
        browser.
      </p>
      <p>
        <button className="Button ButtonPrimary" onClick={start}>
          Auth with Google
        </button>
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p>
        <a href="/privacypolicy.htm">Privacy Policy</a>
      </p>
    </>
  );
};
