import React from "react";
import { Link } from "react-router-dom";
import uuidv4 from "uuid/v4";

import { Search } from "../Search";
import { log } from "../../google";

export class Log extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date(this.props.match.params.date), cards: [] };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ date: new Date(nextProps.match.params.date) });
    this.load();
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const cards = await log(this.state.date);
    this.setState({
      cards: cards.sort((a, b) => a.modifiedTime - b.modifiedTime)
    });
  }

  renderCreated() {
    const created = this.state.cards.filter(
      e => e.createdTime > this.state.date.getTime()
    );
    return (
      <ul>
        {created.map(e => (
          <li key={e.id}>
            <Link to={`/cards/${e.id}`}>{e.name}</Link>&nbsp;
            <span style={{ fontSize: "x-small" }}>
              {new Date(e.modifiedTime).toLocaleTimeString()}
            </span>
          </li>
        ))}
      </ul>
    );
  }

  renderUpdated() {
    const updated = this.state.cards.filter(
      e => e.createdTime < this.state.date.getTime()
    );
    return (
      <ul>
        {updated.map(e => (
          <li key={e.id}>
            <Link to={`/cards/${e.id}`}>{e.name}</Link>&nbsp;
            <span style={{ fontSize: "x-small" }}>
              {new Date(e.modifiedTime).toLocaleTimeString()}
            </span>
          </li>
        ))}
      </ul>
    );
  }

  renderMarkdown() {
    const created = this.state.cards.filter(
      e => e.createdTime > this.state.date.getTime()
    );
    const updated = this.state.cards.filter(
      e => e.createdTime < this.state.date.getTime()
    );
    return (
      <pre>
        ### Created
        {`\n`}
        {created.map(e => `- ref(${e.name})\n`)}
        {`\n\n`}
        ### Updated
        {`\n`}
        {updated.map(e => `- ref(${e.name})\n`)}
      </pre>
    );
  }

  render() {
    const newUrl = `/cards/${uuidv4()}?edit=true`;
    const date = new Date();
    const logUrl = `/log/${date.getFullYear()}-${date.getMonth() +
      1}-${date.getDate()}`;
    return (
      <div>
        <div className="Menu" id="topbar">
          <Search
            setListState={opts => this.setState(opts)}
            history={this.props.history}
            match={this.props.match}
          />
          <Link to="/" className="Button ButtonMenu">
            Home
          </Link>
          <Link to={newUrl} className="Button ButtonMenu">
            Create
          </Link>
          <Link to={logUrl} className="Button ButtonMenu">
            Log
          </Link>
        </div>
        <div className="cards">
          <h1>Log - {this.state.date.toLocaleDateString()}</h1>
          <h2>Created</h2>
          {this.renderCreated()}
          <h2>Updated</h2>
          {this.renderUpdated()}
          <h2>Markdown</h2>
          {this.renderMarkdown()}
        </div>
      </div>
    );
  }
}
