import React from "react";
import { Link } from "react-router-dom";
import uuidv4 from "uuid/v4";

import firebase from "firebase/app";

export class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.db = firebase.firestore();
    this.state = {};
  }

  async componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    const ref = this.db.collection("apiKey").doc(uid);
    const doc = await ref.get();
    if (doc.exists) {
      this.setState({ apiKey: doc.data().key });
    }
  }

  async generateApi() {
    const uid = firebase.auth().currentUser.uid;
    const ref = this.db.collection("apiKey").doc(uid);
    await ref.set({ key: uuidv4(), uid: uid });
    window.location.reload();
  }

  render() {
    return (
      <div>
        <div className="Menu" id="topbar">
          <Link to="/" className="Button ButtonMenu">
            Home
          </Link>
        </div>
        <div className="cards">
          <h1>Settings</h1>
          <h2>API Key</h2>
          <p>
            Your API Key is: <span>{this.state.apiKey}</span>
          </p>
          <button
            onClick={() => this.generateApi()}
            className="Button ButtonPrimary"
          >
            Generate
          </button>
        </div>
      </div>
    );
  }
}
